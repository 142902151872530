<template>
  <PageContainer
    :title="$translations.appointment['reschedule-appointment']"
    :back="actions.back"
  >
    <template v-slot:content>
      <div class="page-segment" v-if="loading">
        <div class="page-segment-content">
          <SkeletonDefault />
        </div>
      </div>

      <div class="page-segment" v-else>
        <div class="page-segment-content">
          <div>
            <div
              class="heading margin-bottom"
              v-text="$translations.general['original-appointment']"
            />
            <SpecialistsAppointmentCard
              v-selectable-container="{ callback: goToDetail, elementLevel: 2 }"
              :show-arrow="true"
              :appointment="appointment"
            />
            <div class="padding-top">
              <div
                class="heading"
                v-text="$translations.dialogs.appointment.reschedule.title"
              />
              <ModalitySelector
                v-model="modality"
                @changed="setModality"
                :showPresentialOption="
                  Boolean(specialists_service.kalendme_in_person_link_id)
                "
              />
              <ServiceCard
                v-if="appointment"
                :service="appointmentService"
                :without-action="true"
              />
            </div>
            <template v-if="appointment.specialist_service">
              <AppointmentDateSelector
                @changed="setDate"
                :specialistId="appointment.specialist.id"
                :serviceId="specialists_service.id"
                :modality="getModality"
                :setDate="appointment.starts_at"
                :reschedule="true"
              />
            </template>
            <div class="padding-y">
              <TextareaField
                :title="$translations.general['reschedule-reason']"
                :placeholder="$translations.general['reschedule-reason']"
                :value="reason"
                @changed="setReason"
              />
            </div>
          </div>

          <Button
            @clicked="appointmentReschedule()"
            :text="$translations.appointment['action-sheet'].reschedule"
            :loading="rescheduling"
            :disable="!isValid"
          />
        </div>
      </div>
    </template>
  </PageContainer>
</template>

<script>
import {
  Appointments,
  SpecialistsServices,
  PageContainer,
  Button,
  TextareaField,
  selectableContainer
} from '@seliaco/red-panda'
import { OriginEnums } from '@/types/origins'
import SkeletonDefault from '@/components/skeletons/SkeletonDefault'
import AppointmentDateSelector from '@/components/fields/AppointmentDateSelector'
import SpecialistsAppointmentCard from '@/components/cards/SpecialistsAppointmentCard'
import AppointmentRedirectMixin from '@/mixins/appointment-redirect-mixin'
import ServiceCard from '@/components/cards/ServiceCard'
import ModalitySelector from '@/components/fields/ModalitySelector'

export default {
  name: 'Reschedule',
  components: {
    SpecialistsAppointmentCard,
    PageContainer,
    SkeletonDefault,
    AppointmentDateSelector,
    TextareaField,
    Button,
    ServiceCard,
    ModalitySelector
  },
  mixins: [AppointmentRedirectMixin],
  data () {
    return {
      loading: true,
      appointment: {},
      appointmentService: null,
      specialists_service: {},
      status: '',
      virtual: null,
      starts_at: null,
      reason: null,
      rescheduling: false,
      originEnums: OriginEnums,
      modality: 'ONLINE',
      actions: {
        back: {
          callback: () => {
            this.$router.replace({
              name: 'AppointmentDetail',
              params: {
                id: this.$route.params.id
              }
            })
          }
        }
      }
    }
  },
  created () {
    this.getAppointment()
  },
  methods: {
    async getAppointment () {
      this.loading = true

      const appointments = await Appointments.read({
        columns: `
          id,
          starts_at,
          status,
          reason,
          specialist_service (
            id,
            service_type (
              name:name_${this.$store.getters.language.lang},
              description:description_${this.$store.getters.language.lang},
              duration,
              icon_name
            )
          ),
          specialist (
            id,
            speciality,
            user (
              profile_picture,
              first_name,
              last_name
            )
          ),
          patient (
            id,
            first_name,
            last_name
          ),
          virtual
        `,
        eq: {
          id: this.$route.params.id
        },
        orderBy: {
          column: 'starts_at',
          params: {
            ascending: false
          }
        }
      }).catch(() => this.showErrorToast('Ocurrió un error obteniendo la cita'))

      if (!appointments.data[0]) {
        this.showErrorToast('La cita no fue encontrada')
      }
      this.appointment = appointments.data[0]
      this.appointment.specialist_service = {
        ...this.appointment.specialist_service,
        ...this.appointment.specialist_service.service_type
      }
      this.status = this.appointment.status
      this.virtual = this.appointment.virtual

      this.modality = this.virtual ? 'ONLINE' : 'FACE_TO_FACE'

      const services = await SpecialistsServices.read({
        columns: '*',
        eq: {
          service: this.appointment.specialist_service.id,
          specialist: this.appointment.specialist.id
        }
      }).catch(() =>
        this.showErrorToast(
          'Ocurrió un error obteniendo el servicio de la cita'
        )
      )

      if (!services.data[0]) {
        this.showErrorToast('El servicio de la cita no existe')
      }

      this.specialists_service = services.data[0]
      this.appointmentService = {
        service: {
          id: this.appointment.specialist_service.id,
          ...this.appointment.specialist_service.service_type
        }
      }
      this.loading = false
    },
    async appointmentReschedule () {
      this.rescheduling = true
      await Appointments.update(this.appointment.id, {
        starts_at: this.starts_at,
        rescheduled_by: this.$store.getters['auth/user'].id,
        rescheduling_origin: this.originEnums.specialist,
        rescheduling_reason: this.reason,
        patient: this.appointment.patient.id,
        specialist: this.appointment.specialist.id,
        specialist_service: this.appointment.specialist_service.id,
        virtual: this.virtual
      })
        .then(() => {
          this.$toast({
            severity: 'success',
            text: this.$translations.success['appointment-rescheduled']
          })
          this.$router.push({
            name: 'Home'
          })
        })
        .catch(() => {
          this.$toast({
            severity: 'error',
            text: this.$translations.error.default
          })
        })
        .finally(() => {
          this.rescheduling = false
        })
    },
    showErrorToast (text = this.$translations.error.default) {
      this.$toast({
        severity: 'error',
        text
      })
    },
    setModality (option) {
      this.virtual = option === 'ONLINE'
    },
    setDate (value) {
      if (!value || (value && !value.code)) {
        this.starts_at = null
        return
      }
      this.starts_at = value.code
    },
    setReason (value) {
      this.reason = value
    }
  },
  computed: {
    isValid () {
      return Boolean(
        this.starts_at && this.appointment.starts_at !== this.starts_at
      )
    },
    getModality () {
      return this.modality === 'ONLINE' ? 'VIRTUAL' : 'FACE_TO_FACE'
    }
  },
  directives: { selectableContainer }
}
</script>
