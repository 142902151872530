<template>
  <div class="padding-all  margin-bottom">
    <div class="margin-bottom">
      <Skeleton width="230px" height="30px" borderRadius="16px" />
    </div>

    <div class="margin-y">
      <Skeleton width="180px" height="24px" borderRadius="16px" />
    </div>

    <div class="margin-bottom-xs" v-for="index in 3" :key="index">
      <Skeleton width="100%" height="97px" borderRadius="16px" />
    </div>
  </div>
</template>

<script>
import Skeleton from 'primevue/skeleton'
export default {
  components: {
    Skeleton
  }
}
</script>
